$(function(){

    $("#search-nav").on("click", function(event ){

        event.preventDefault();
        $(".nav-search-input").show();

        var valueSearch = $(".nav-search-input").val();
        if(valueSearch!="")
        {
            $("#formtoseach").trigger("submit");
        }
    });


    //Fill blank space of fixed header on load
    $("body").prepend('<div id="sc-navbar-fix-space" style="width: 100%; height: ' + $(".navbar.navbar-default").height() + 'px;"></div>');
    //Fill blank space of fixed header on resize
    $(window).resize(function(){
        $("#sc-navbar-fix-space").height( $(".navbar.navbar-default").height() );
    });
    //debug
    //console.log( "Nav height:" + $(".navbar.navbar-default").height() );

	
    //Main toggler (Desktop / Tablet / Mobile)
    $(".sc-hamburger-button").on("click", function(){
    	$("#sc-megamenu").slideToggle(400, function(){
            //console.log('Expanded');
            //Add helper classname to fixed header on collapse and expand megamenu
            if( $(".sc-sticky-header").hasClass("expanded") ){
                $(".sc-sticky-header").removeClass("expanded");
                $(".sc-megamenu-close-x").removeClass("sc-megamenu-close-x-show");
                $(".sc-hamburger-inner > .hamb-lines").show();
            }else if( $("#sc-megamenu").height() >= 1 ){
                $(".sc-sticky-header").addClass("expanded");
                $(".sc-megamenu-close-x").addClass("sc-megamenu-close-x-show");
                $(".sc-hamburger-inner > .hamb-lines").hide();

            }

        });

    	//Mobile
	    //Prevent some toggle functions on desktop / tablets (allow only on mobile)
	    if( $(window).width()<=767 ){
        	//Close all opened items on touch hamburguer button
        	
        	   //Change icons to down
        	$(".sc-mobile-menu-item-toggler").each(function(){
        		$(this).find('.fa').removeClass('fa-angle-up').addClass('fa-angle-down');
        	});
        	//Slide up items
        	$("ul.sc-megamenu-list").slideUp(400);
        }
    });


    //Mobile
    //Item toggler
    $(".sc-mobile-menu-item-toggler:not(.sc-cancel-toggle)").on("click", function(){
	    
	    //Prevent some toggle functions on desktop / tablets (allow only on mobile)
        if( $(window).width()<=767 ){
        	//Toggle icon (up/down)
        	var arrow_icon = $(this).find(".fa");
        	if( arrow_icon.hasClass('fa-angle-down') ){
        		arrow_icon.removeClass('fa-angle-down').addClass('fa-angle-up');
        	}else{
        		arrow_icon.removeClass('fa-angle-up').addClass('fa-angle-down');
        	}
            var clicked_item = $(this).next();
            $(".sc-megamenu-list").slideUp(300, function(){
                setTimeout(function(){
                    clicked_item.slideDown(300);
                },10);
            });
        	
        }
    });


});